<template>
  <div class="home">

    <div class="bar top-bar">
      
      <!-- <div id="logo">
        <a href="index.html">
          <img src="style/img/logo.png" alt="logo" />
        </a>
      </div> -->
      
    </div>
    
    <!-- <nav>
      <ul class="pull-left social">
        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
        <li><a href="#"><i class="fa fa-pinterest"></i></a></li>
        <li><a href="#"><i class="fa fa-rss"></i></a></li>
      </ul>
      
      <ul class="pull-right">
        <li><a href="#header-video">Home</a></li>
        <li>
          <a href="#work">Work</a>
          <ul>
            <li>
              <a href="single-portfolio.html">Video Post</a>
            </li>
            <li>
              <a href="single-portfolio-image.html">Image Post</a>
            </li>
            <li>
              <a href="single-portfolio-gallery.html">Gallery Post</a>
            </li>
          </ul>
        </li>
        <li><a href="#services">Services</a></li>
        <li><a href="#pricing">Pricing</a></li>
        <li><a href="#contact">Contact</a></li>
        <li>
          <a href="blog.html">Blog</a>
          <ul>
            <li>
              <a href="single-post.html">Single Post</a>
            </li>
          </ul>
        </li>
      </ul>
    </nav> -->
    
    <!-- <a href="#" id="menu-toggle"><span></span></a> -->
    <!-- video header plugin & documentation here: http://vodkabears.github.io/vide/ -->
    <div 
      id="header-video"
      class="full-height black-wrapper" 
      style="width: 100%; height: 500px;" 
      data-vide-bg="mp4: style/video/ocean, webm: style/video/ocean, ogv: style/video/ocean, poster: style/video/ocean.jpg" 
      data-vide-options="position: 0% 50%"
    >
    <!-- replace the above options with your own -->
      
      <!-- <div class="bar left-bar"></div>
      <div class="bar right-bar"></div>
      <div class="bar bottom-bar"></div> -->
    
      <div class="page-header text-center">
        <!-- multiple h1's are perfectly acceptable on a page in valid HTML5, when wrapped in individual sections, they're 100% semantically correct -->
        <h1>The Big Wave<small>Captains of industry</small></h1>
        <!-- seriously -->
      </div>
      
      <!-- <a id="down-link" href="#"><i class="icon-mouse"></i></a> -->
    
    </div>
    <!-- end the video header -->
    
    <!-- page sections are what we'll use for the page scroll effect -->
    <!-- <section id="services" class="page-section light-wrapper no-bottom">
      <div class="container-fluid">
      
        <div class="row">
        
          <div class="page-header text-center">
            multiple h1's are perfectly acceptable on a page in valid HTML5, when wrapped in individual sections, they're 100% semantically correct
            <h1>Animated Service Boxes<small>Subtext for header</small></h1>
            seriously
          </div>
        
          <div class="col-md-3 service even">
            <i class="fa fa-diamond"></i>
            <h3>500+ Retina Icons</h3>
            <p>Pellentesque sit amet sem et purus pretium consectetuer. Pellentesque sit amet sem et purus pretium consectetuer.</p>
            <a href="#" class="btn btn-white tm15">Learn More</a>
          </div>
          
          <div class="col-md-3 service even">
            <i class="fa fa-file-o"></i>
            <h3>A Beautiful Portfolio</h3>
            <p>Pellentesque sit amet sem et purus pretium consectetuer. Pellentesque sit amet sem et purus pretium consectetuer.</p>
            <a href="#" class="btn btn-white tm15">Learn More</a>
          </div>
          
          <div class="col-md-3 service even">
            <i class="fa fa-pencil"></i>
            <h3>Delicious Blog</h3>
            <p>Pellentesque sit amet sem et purus pretium consectetuer. Pellentesque sit amet sem et purus pretium consectetuer.</p>
            <a href="#" class="btn btn-white tm15">Learn More</a>
          </div>
          
          <div class="col-md-3 service even">
            <i class="fa fa-envelope-o"></i>
            <h3>Contact Form</h3>
            <p>Pellentesque sit amet sem et purus pretium consectetuer. Pellentesque sit amet sem et purus pretium consectetuer.</p>
            <a href="#" class="btn btn-white tm15">Learn More</a>
          </div>
        
        </div>
        
        <div class="row">
        
          <div class="col-md-3 service odd">
            <i class="fa fa-html5"></i>
            <h3>Elite Code</h3>
            <p>Pellentesque sit amet sem et purus pretium consectetuer. Pellentesque sit amet sem et purus pretium consectetuer.</p>
            <a href="#" class="btn btn-white tm15">Learn More</a>
          </div>
          
          <div class="col-md-3 service odd">
            <i class="fa fa-skyatlas"></i>
            <h3>Crisp Design</h3>
            <p>Pellentesque sit amet sem et purus pretium consectetuer. Pellentesque sit amet sem et purus pretium consectetuer.</p>
            <a href="#" class="btn btn-white tm15">Learn More</a>
          </div>
          
          <div class="col-md-3 service odd">
            <i class="fa fa-css3"></i>
            <h3>CSS3 Goodness</h3>
            <p>Pellentesque sit amet sem et purus pretium consectetuer. Pellentesque sit amet sem et purus pretium consectetuer.</p>
            <a href="#" class="btn btn-white tm15">Learn More</a>
          </div>
          
          <div class="col-md-3 service odd">
            <i class="fa fa-film"></i>
            <h3>Video Header</h3>
            <p>Pellentesque sit amet sem et purus pretium consectetuer. Pellentesque sit amet sem et purus pretium consectetuer.</p>
            <a href="#" class="btn btn-white tm15">Learn More</a>
          </div>
        
        </div>
        
      </div>
    </section> -->
    <!-- end page section -->
    
    <!-- page sections are what we'll use for the page scroll effect -->
    <!-- <section id="work" class="page-section dark-wrapper no-bottom">
      <div class="wrapper">
        
        <div class="page-header text-center">
          multiple h1's are perfectly acceptable on a page in valid HTML5, when wrapped in individual sections, they're 100% semantically correct
          <h1>Our Work<small>Subtext for header</small></h1>
          seriously
        </div>
        
      </div>
      
      <ul id="filters">
        <li>
          <a href=".item" class="btn active">All</a>
        </li>
        <li>
          <a href=".web-design" class="btn">Web Design</a>
        </li>
        <li>
          <a href=".development" class="btn">Development</a>
        </li>
        <li>
          <a href=".design" class="btn">Design</a>
        </li>
        <li>
          <a href=".illustration" class="btn">Illustration</a>
        </li>
      </ul>
      
      <div id="loader">
        <div class="item"></div>
      </div>
      
      <div id="container">
      
        <div class="item small web-design development">
          <div class="item-inner">
            <a href="single-portfolio.html">
              <img src="style/img/p1.jpg" alt="Portfolio Item" />
              <div class="project-title">
                <div class="title-wrapper">
                  <h3>Project With Video</h3>
                  <span>Web Design, Development</span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="item small design illustration">
          <div class="item-inner">
            <a href="single-portfolio-image.html">
              <img src="style/img/p2.jpg" alt="Portfolio Item" />
              <div class="project-title">
                <div class="title-wrapper">
                  <h3>Project With Image</h3>
                  <span>Design, Illustration</span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="item small development illustration">
          <div class="item-inner">
          <a href="single-portfolio-gallery.html">
            <img src="style/img/p3.jpg" alt="Portfolio Item" />
            <div class="project-title">
              <div class="title-wrapper">
                <h3>Project With Gallery</h3>
                <span>Web Design, Development</span>
              </div>
            </div>
          </a>
          </div>
        </div>
        <div class="item small web-design development">
          <div class="item-inner">
            <a href="single-portfolio.html">
              <img src="style/img/p4.jpg" alt="Portfolio Item" />
              <div class="project-title">
                <div class="title-wrapper">
                  <h3>Project With Video</h3>
                  <span>Web Design, Development</span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="item small design illustration">
          <div class="item-inner">
          <a href="single-portfolio-image.html">
            <img src="style/img/p5.jpg" alt="Portfolio Item" />
            <div class="project-title">
              <div class="title-wrapper">
                <h3>Project With Image</h3>
                <span>Web Design, Development</span>
              </div>
            </div>
          </a>
          </div>
        </div>
        <div class="item small development illustration">
          <div class="item-inner">
          <a href="single-portfolio-gallery.html">
            <img src="style/img/p6.jpg" alt="Portfolio Item" />
            <div class="project-title">
              <div class="title-wrapper">
                <h3>Project With Gallery</h3>
                <span>Web Design, Development</span>
              </div>
            </div>
          </a>
          </div>
        </div>
        <div class="item small web-design development">
          <div class="item-inner">
            <a href="single-portfolio.html">
              <img src="style/img/p7.jpg" alt="Portfolio Item" />
              <div class="project-title">
                <div class="title-wrapper">
                  <h3>Project With Video</h3>
                  <span>Web Design, Development</span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="item small design illustration">
          <div class="item-inner">
          <a href="single-portfolio-image.html">
            <img src="style/img/p8.jpg" alt="Portfolio Item" />
            <div class="project-title">
              <div class="title-wrapper">
                <h3>Project With Image</h3>
                <span>Web Design, Development</span>
              </div>
            </div>
          </a>
          </div>
        </div>

      </div>
      
      <div class="text-center">
        <a href="load-more.html" class="load-more-btn btn tm40 bm70" data-loading="Loading...">Load More</a>
      </div>
      
    </section> -->
    <!-- end page section -->
    
    <!-- page sections are what we'll use for the page scroll effect -->
    <!-- <section id="services-alt" class="page-section light-wrapper">
      <div class="container-fluid max-1400">
        <div class="row">
        
          <div class="page-header text-center">
            multiple h1's are perfectly acceptable on a page in valid HTML5, when wrapped in individual sections, they're 100% semantically correct
            <h1>Beautiful Services<small>Subtext for header</small></h1>
            seriously
          </div>
          
          <div class="col-md-3">
            <div class="service-3">
              <i class="fa fa-hdd-o"></i>
              <div class="service-3-text">
                <h4>500+ Retina Icons</h4>
                <p>Pellentesque sit amet sem et purus pretium consectetuer.</p>
              </div>
            </div>
            <div class="service-3">
              <i class="fa fa-file-o"></i>
              <div class="service-3-text">
                <h4>A Beautiful Portfolio</h4>
                <p>Pellentesque sit amet sem et purus pretium consectetuer.</p>
              </div>
            </div>
            <div class="service-3">
              <i class="fa fa-pencil"></i>
              <div class="service-3-text">
                <h4>Delicious Blog</h4>
                <p>Pellentesque sit amet sem et purus pretium consectetuer.</p>
              </div>
            </div>
          </div>
          
          <div class="col-md-6 text-center">
            <img src="style/img/phone1.png" alt="" />
          </div>
          
          <div class="col-md-3">
            <div class="service-3">
              <i class="fa fa-html5"></i>
              <div class="service-3-text">
                <h4>Elite Code</h4>
                <p>Pellentesque sit amet sem et purus pretium consectetuer.</p>
              </div>
            </div>
            <div class="service-3">
              <i class="fa fa-css3"></i>
              <div class="service-3-text">
                <h4>CSS3 Goodness</h4>
                <p>Pellentesque sit amet sem et purus pretium consectetuer.</p>
              </div>
            </div>
            <div class="service-3">
              <i class="fa fa-skyatlas"></i>
              <div class="service-3-text">
                <h4>Crisp Design</h4>
                <p>Pellentesque sit amet sem et purus pretium consectetuer.</p>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </section> -->
    <!-- end page section -->
    
    <!-- page sections are what we'll use for the page scroll effect -->
    <!-- <section id="services-inverse" class="page-section dark-wrapper">
      <div class="container">
        <div class="row">
        
          <div class="page-header text-center">
            multiple h1's are perfectly acceptable on a page in valid HTML5, when wrapped in individual sections, they're 100% semantically correct
            <h1>Simple Service Boxes<small>Subtext for header</small></h1>
            seriously
          </div>
        
          <div class="col-sm-4 service-alt">
            <div class="inner">
              <i class="fa fa-hdd-o"></i>
              <h3>500+ Retina Icons</h3>
              <p>Pellentesque sit amet sem et purus pretium consectetuer. Pellentesque sit amet sem et purus pretium consectetuer.</p>
              <a href="#" class="btn tm15">Learn More</a>
            </div>
          </div>
          
          <div class="col-sm-4 service-alt dark">
            <div class="inner">
              <i class="fa fa-film"></i>
              <h3>Video Header</h3>
              <p>Pellentesque sit amet sem et purus pretium consectetuer. Pellentesque sit amet sem et purus pretium consectetuer.</p>
              <a href="#" class="btn btn-white tm15">Learn More</a>
            </div>
          </div>
          
          <div class="col-sm-4 service-alt">
            <div class="inner">
              <i class="fa fa-skyatlas"></i>
              <h3>Crisp Design</h3>
              <p>Pellentesque sit amet sem et purus pretium consectetuer. Pellentesque sit amet sem et purus pretium consectetuer.</p>
              <a href="#" class="btn tm15">Learn More</a>
            </div>
          </div>
        
        </div>
      </div>
    </section> -->
    <!-- end page section -->
    
    <!-- page sections are what we'll use for the page scroll effect -->
    <!-- <section class="page-section parallax" style="background-image: url('style/img/mountains.jpg');">
      <div class="container-fluid">
        <div class="row">
          
          <div class="page-header text-center">
            multiple h1's are perfectly acceptable on a page in valid HTML5, when wrapped in individual sections, they're 100% semantically correct
            <h1>Example page header<small>Subtext for header</small></h1>
            seriously
          </div>
          
          <div class="col-sm-12 text-center">
            <a href="#" class="btn btn-white">View All Prices</a>
          </div>
          
        </div>
      </div>
    </section> -->
    <!-- end page section -->
    
    <!-- page sections are what we'll use for the page scroll effect -->
    <!-- <section id="pricing" class="page-section dark-wrapper">
      <div class="container">
        <div class="row">
          
          <div class="page-header text-center">
            multiple h1's are perfectly acceptable on a page in valid HTML5, when wrapped in individual sections, they're 100% semantically correct
            <h1>Example page header<small>Subtext for header</small></h1>
            seriously
          </div>
          
          <div class="col-sm-4">
            <section class="pricing-table">
              <h3>Basic<span>Boring & Dull</span></h3>
              <h2>
                <span class="price"><sup>$</sup>1</span>
                <span class="deets">per week</span>
              </h2>
              <ul>
                <li>such graphic.</li>
                <li>very github.</li>
                <li>so filler.</li>
                <li><del>lorem CSS.</del></li>
                <li><del>so dogescript.</del></li>
                <li><del>very CSS.</del></li>
              </ul>
              <a href="#" class="btn btn-primary">Choose Plan</a>
            </section>
          </div>
          
          <div class="col-sm-4">
            <section class="pricing-table">
              <h3>Designer<span>Critics Choice</span></h3>
              <h2>
                <span class="price"><sup>$</sup>15</span>
                <span class="deets">per month</span>
              </h2>
              <ul>
                <li>such graphic.</li>
                <li>very github.</li>
                <li>so filler.</li>
                <li>lorem CSS.</li>
                <li><del>so dogescript.</del></li>
                <li><del>very CSS.</del></li>
              </ul>
              <a href="#" class="btn btn-primary">Choose Plan</a>
            </section>
          </div>
          
          <div class="col-sm-4">
            <section class="pricing-table">
              <h3>Developer<span>Everything Included</span></h3>
              <h2>
                <span class="price"><sup>$</sup>25</span>
                <span class="deets">per day</span>
              </h2>
              <ul>
                <li>such graphic.</li>
                <li>very github.</li>
                <li>so filler.</li>
                <li>lorem CSS.</li>
                <li>so dogescript.</li>
                <li>very CSS.</li>
              </ul>
              <a href="#" class="btn btn-primary">Choose Plan</a>
            </section>
          </div>
          
          <div class="col-sm-12 text-center">
            <a href="#" class="btn btn-primary btn-lg">View All Prices</a>
          </div>
          
        </div>
      </div>
    </section> -->
    <!-- end pricing section -->
    
    <!-- page sections are what we'll use for the page scroll effect -->
    <!-- <section id="about" class="page-section light-wrapper no-bottom">
    
      <div class="container">
        <div class="row">
          
          <div class="page-header text-center">
            multiple h1's are perfectly acceptable on a page in valid HTML5, when wrapped in individual sections, they're 100% semantically correct
            <h1>Our Team<small>We Only Hire The Best</small></h1>
            seriously
          </div>

        </div>
      </div>
      
      <div class="container-fluid">
        <div class="row">
          
          <article class="col-md-6 team-member text-right">
            <div class="row">
            
              <div class="col-md-6">
                <div class="page-header">
                  multiple h1's are perfectly acceptable on a page in valid HTML5, when wrapped in individual sections, they're 100% semantically correct
                  <h1>Victor Petrov<small>Lead Developer</small></h1>
                  seriously
                  <p>Pellentesque sit amet sem et purus pretium consectetuer. Pellentesque sit amet sem et purus pretium consectetuer.</p>
                </div>
              </div>
              
              <div class="col-md-6">
                <img src="style/img/team1-bw.png" alt="" />
              </div>
            
            </div>
          </article>
          
          <article class="col-md-6 team-member text-left">
            <div class="row">
              
              <div class="col-md-6">
                <img src="style/img/team2-bw.png" alt="" />
              </div>
              
              <div class="col-md-6">
                <div class="page-header">
                  multiple h1's are perfectly acceptable on a page in valid HTML5, when wrapped in individual sections, they're 100% semantically correct
                  <h1>Johan Simmons<small>Lead Designer</small></h1>
                  seriously
                  <p>Pellentesque sit amet sem et purus pretium consectetuer. Pellentesque sit amet sem et purus pretium consectetuer.</p>
                </div>
              </div>
            
            </div>
          </article>
          
        </div>
      </div>
      
    </section> -->
    <!-- end about section -->
    
    <!-- page sections are what we'll use for the page scroll effect -->
    <!-- <section id="contact" class="page-section dark-wrapper">
      <div class="container">
        <div class="row">
          
          <div class="page-header text-center">
            multiple h1's are perfectly acceptable on a page in valid HTML5, when wrapped in individual sections, they're 100% semantically correct
            <h1>Contact Us<small>Here's a Working Contact Form</small></h1>
            seriously
          </div>
          
          <div class="col-sm-8">
            <div id="message"></div>
            <form method="post" action="sendemail.php" id="contactform">
              <input type="text" name="name" id="name" placeholder="Name" />
              <input type="text" name="email" id="email" placeholder="Email" />
              <input type="text" name="website" id="website" placeholder="Website" />
              <textarea name="comments" id="comments" placeholder="Comments"></textarea>
              <input type="submit" name="submit" value="Submit" />
            </form>
          </div>
          
          <div class="col-sm-4 lp30">
            <h5>Contact Us</h5>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Morbi commodo, ipsum sed pharetra gravida, orci magna rhoncus neque, id pulvinar.</p>
            <ul class="fa-ul">
              <li class="bm15"><i class="fa fa-map-marker fa-fw fa-2x fa-li"></i> York, England</li>
              <li class="bm15"><i class="fa fa-phone-square fa-fw fa-2x fa-li"></i> +00 (123) 456 78 90</li>
              <li class="bm15"><i class="fa fa-laptop fa-fw fa-2x fa-li"></i> <a href="first.last@email.com">first.last@email.com</a></li>
            </ul>
          </div>	 
          
        </div>
      </div>
    </section> -->
    <!-- end contact section -->
    
    <!-- <footer id="main-footer">
      <div class="container">
        <div class="row">
        
          <div class="col-md-3 rp30">
            <div class="widget">
              <img src="style/img/logo-light.png" alt="logo" class="bm30" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at felis aliquet congue.</p>
            </div>
          </div>
          
          <div class="col-md-3 rp30">
            <div class="widget">
              <h5>About Us</h5>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at felis aliquet congue. Ut a est eget ligula molestie gravida. Curabitur massa</p>
            </div>
          </div>
          
          <div class="col-md-3 rp30">
            <div class="widget">
              <h5>Categories</h5>
              <ul>
                <li>Journal</li>
                <li>Design</li>
                <li>Illustration</li>
                <li>Journal</li>
                <li>Design</li>
              </ul>
            </div>
          </div>
          
          <div class="col-md-3">
            <div class="widget">
              <h5>Instagram</h5>
              <div class="instafeed"></div>
            </div>
          </div>
          
        </div>
      </div>
    </footer> -->
    
    <!-- <footer id="sub-footer">
      <div class="container">
        <div class="row">
          
          <div class="col-md-6">
            <p>Copyright Tom Rhodes.</p>
          </div>
          
          <div class="col-md-6">
            <ul class="pull-right social">
              <li><a href="#"><i class="fa fa-twitter"></i></a></li>
              <li><a href="#"><i class="fa fa-facebook"></i></a></li>
              <li><a href="#"><i class="fa fa-pinterest"></i></a></li>
              <li><a href="#"><i class="fa fa-rss"></i></a></li>
            </ul>
          </div>
          
        </div>
      </div>
    </footer> -->


    <!-- jQuery (necessary for Bootstrap's JavaScript plugins)
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js"></script>
    Include all compiled plugins (below), or include individual files as needed
    <script src="style/bootstrap/js/bootstrap.min.js"></script>
    Main Custom JS for this template
    <script src="style/js/plugins.js"></script>
    <script src="style/js/main.js"></script> -->
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>

<style scoped lang="scss">
// override styles in /public/

.bar {
  &.top-bar {
    background: none;
    pointer-events: none;
  }
}

#menu-toggle {
  z-index: 1000;

  &.active {
    span {
      background-color: transparent;

      &:before,
      &:after {
        background-color: rgba(0,0,0,0.85);
      }
    }
  }

  span,
  span:before,
  span:after {
    background: rgba(255, 255, 255, 0.85);
  }
}

nav {
  padding-right: 150px;
  &.active {
    top: 0;
  }
}

</style>